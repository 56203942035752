<template>
  <div data-testid="vonage-home-page-header-section" class="relative overflow-hidden select-none">
    <div class="absolute top-[-48px]">
      <StoryblokImage class="bg-contain h-[300px] bg-no-repeat" :image="blok.backgroundImage" />
    </div>
    <SectionWithMaxWidth>
      <div class="flex flex-col lg:flex-row md:flex-row pt-12 pb-6 backdrop-blur-[1px]">
        <div>
          <div class="title">{{ blok.title }}</div>
          <div class="sub-title mt-3">{{ blok.subtitle }}</div>
        </div>
        <div class="flex-grow" />
        <div
          v-if="user.info && !isUserLoggedIn(user.info)"
          data-testid="login-signup-buttons"
          class="flex items-end justify-start xl:items-start flex-row large:flex-col medium:flex-col xxs:items-start xxs:flex-col pt-3 gap-1"
        >
          <NuxtLink :href="VonageHomePageRegistrationUrl" type="button">
            <VButton
              data-testid="createAFreeAccount"
              appearance="filled"
              :label="$t('vonage.homePage.header.createAFreeAccount')"
              connotation="cta"
              class="mr-1.5"
              icon="arrow-right-line"
              icon-trailing
            />
          </NuxtLink>
          <a :href="signInUrl" type="button">
            <VButton
              data-testid="login"
              :label="$t('vonage.homePage.header.login')"
              appearance="outlined"
              icon="arrow-right-line"
              icon-trailing
            />
          </a>
        </div>
      </div>
    </SectionWithMaxWidth>
  </div>
</template>

<script setup lang="ts">
import { VButton } from '@vonage/vivid-vue';
import type { PropType } from 'vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import type { VonageHomePageHeaderSection } from '@/types/storyblok';
import { useUserStore } from '@/store/user';
import { isUserLoggedIn } from '@/utils/user';
import { VonageHomePageRegistrationUrl } from '@/constants';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';
import StoryblokImage from '@/components/storyblok/StoryblokImage.vue';

const route = useRoute();
const signInUrl = computed(() => (route ? `/sign-in?redirect=${encodeURI(route.fullPath)}` : ''));
const user = useUserStore();

defineProps({
  blok: { type: Object as PropType<VonageHomePageHeaderSection>, required: true },
});
</script>

<style lang="scss" scoped>
.title {
  font-size: 52px;
  line-height: 68px;
}

.sub-title {
  font-size: 32px;
  line-height: 44px;
}

.title,
.sub-title {
  font-weight: 500;
  color: var(--vvd-color-neutral-700);
}
</style>
